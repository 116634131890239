import Vue from "vue";
import VueI18n from 'vue-i18n'
import en from './en'
import ja from "./ja"
Vue.use(VueI18n);   // 全局注册国际化包

// 准备翻译的语言环境信息
const i18n = new VueI18n({
    locale: "jp",   // 初始化英文
    messages: {
        "en":en,
        "jp":ja
    }
}); 

export default i18n